import { useEffect } from 'react';
import { MdOutlineFilterAlt } from 'react-icons/md';
import './index.css';

let time = () => void 0;
let start = 0;
let load = 0;
let closeElem = null;
let closeIcon = null;
let body = null

const handleFilter = (evnt) => {
  let filter = document.querySelector('.myfilters');
  const filtersOnly = document.querySelector('.filters .only');
  if(!body.classList.contains('acctpage')) filter = filtersOnly;

  const viewmode = document.querySelector('.viewmode');
  const newloans = document.querySelector('.newloans');
  const backloans = document.querySelector('.rollBackLoans');
  const close = filter.querySelector('.close');
  const mobileBackDrop = document.querySelector('.mobileBackDrop');

  if(!filter.checkVisibility() && evnt.type === 'click'){
    filter.style.display = 'block';
    if(viewmode){
      viewmode.style.display = 'block';
      newloans.style.display = 'block';
      backloans.style.display = 'block';
    }
    body.classList.add('mobile');

    if(!close){
      // Inject close button
      const clone = closeElem.cloneNode(true);
      clone.addEventListener('click', handleFilter);
      filter.append(clone);

      if(body.classList.contains('acctpage')){
        const dropsearch = document.querySelector('.account .dropsearch');
        const select = dropsearch.querySelector('select');
        if(viewmode) dropsearch.classList.add('admin');
        select.addEventListener('click', (evnt) =>
        handleDropSearch(evnt, dropsearch, clone.cloneNode(true)));
      }
    }

    if(!mobileBackDrop){
      // Inject mobile backdrop
      const elem = document.createElement('div');
      elem.className = 'mobileBackDrop';
      elem.style.display = 'block';
      document.querySelector('#root').append(elem);

      // Add class to elements
      if(viewmode){
        const view = viewmode.querySelector('select');
        const mode = view.value.toLowerCase();
        const spacer = filtersOnly.querySelector('.filter:nth-child(2)');
        spacer.classList.add('level', mode);
        viewmode.classList.add('level', mode);
      }
    } else mobileBackDrop.style.display = 'block';
  } else {
    filter.removeAttribute('style');
    if(viewmode){
      viewmode.removeAttribute('style');
      newloans.removeAttribute('style');
      backloans.removeAttribute('style');
    }
    mobileBackDrop.removeAttribute('style');
    body.classList.remove('mobile');
  }
};

const handleDropSearch = (evnt, parent, close) => {
  const filts = document.querySelector('.filts');
  if(filts.checkVisibility() && evnt.type === 'click'){
    filts.style.display = 'none';
    setTimeout(() => {
      // Add event for Search
      attachSearchEvent(parent, handleDropSearch);
      // Add event for All Investors option
      attachDefItemEvent(parent, handleDropSearch);
      // Add event for Investors option
      attachResItemsEvent(parent, handleDropSearch);
      // Add event for Load more
      attachLoadMoreEvent(parent, handleDropSearch);

      close.addEventListener('click', handleDropSearch);

      const cloneClose = closeIcon.cloneNode(true);
      cloneClose.addEventListener('click', () => close.click());

      parent.querySelector('.dropdown-menu').append(close, cloneClose);
    }, 1);
  } else {
    filts.removeAttribute('style');
    body.click();
    start = 0;
  }
};

const attachSearchEvent = (elem, func) => {
  elem.querySelector('[name="search"]')
  .addEventListener('keydown', () => {
    clearTimeout(time);
    time = setTimeout(() => {
      attachResItemsEvent(elem, func);
      attachLoadMoreEvent(elem, func);
    }, 200);
  });
}

const attachDefItemEvent = (elem, func) => {
  elem.querySelector('.dropdown-item')
  .addEventListener('click', func);
};

const attachResItemsEvent = (elem, func) => {
  elem.querySelectorAll('.res .dropdown-item')
  .forEach(a => a.addEventListener('click', func));
};

const attachLoadMoreEvent = (elem, func) => {
  load = elem.querySelectorAll('.res .dropdown-item').length;
  elem.querySelector('.res span')
  .addEventListener('click', () =>
  handleLoadMore(elem, func));
};

const handleLoadMore = (elem, func) => {
  start++;
  for(let n = (load * start); n <= (load * (start + 1)); n++){
    elem.querySelectorAll(`.res .dropdown-item:nth-child(${n + 1})`)
    .forEach(a => a.addEventListener('click', func));
  }
};

function FilterIcon(){
  useEffect(() => {
    document.querySelector('.filterIcon')
    .addEventListener('click', handleFilter);

    body = document.body;

    closeElem = document.createElement('button');
    closeElem.innerHTML = 'Close';
    closeElem.className = 'close';

    closeIcon = document.createElement('span');
    closeIcon.innerHTML = '&times;';
    closeIcon.className = 'dsCloseIcon';

    const filterIcon = document
    .querySelector('.filterIcon');

    if(filterIcon.checkVisibility()){
      // Setup Dropsearch
      const setDropSearch = (dsElem) => {
        if(dsElem){
          const { zIndex } = window.getComputedStyle(dsElem);
          const dsSelect = dsElem.querySelector('select');

          const clone = closeElem.cloneNode(true);
          clone.addEventListener('click', () => {
            dsElem.style.zIndex = zIndex;
            body.classList.remove('mobile');
            body.click();
          });

          const cloneClose = closeIcon.cloneNode(true);
          cloneClose.addEventListener('click', () => clone.click());

          dsSelect.addEventListener('click', () => {
            dsElem.style.zIndex = zIndex + 1;
            body.classList.add('mobile');
            setTimeout(() => {
              // Add event for Search
              attachSearchEvent(dsElem, () => clone.click());
              // Add event for All Investors option
              attachDefItemEvent(dsElem, () => clone.click());
              // Add event for Investors option
              attachResItemsEvent(dsElem, () => clone.click());
              // Add event for Load more
              attachLoadMoreEvent(dsElem, () => clone.click());

              // Inject close button
              dsElem
              .querySelector('.dropdown-menu')
              .append(clone, cloneClose);
            }, 1);
          });
        }
      };
      // /Setup Dropsearch

      // Dropsearch Comms
      setDropSearch(document.querySelector('.commissions .dropsearch'));
      setDropSearch(document.querySelector('.distributions .dropsearch'));
    }
  }, []);

  return <span className="filterIcon"><MdOutlineFilterAlt size="25" /></span>;
}

export default FilterIcon;